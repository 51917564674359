import { ResponsiveBar } from "@nivo/bar";
import React, { FunctionComponent } from "react";

export interface CostPerProjectChartProps {
  data: { project: string; amount: number }[];
}

export const CostPerProjectChart: FunctionComponent<CostPerProjectChartProps> = ({
  data,
}) => {
  return (
    <ResponsiveBar
      tooltipFormat={(value) =>
        `$${Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })}`
      }
      data={data.sort((a, b) => a.project.localeCompare(b.project))}
      keys={["amount"]}
      indexBy="project"
      margin={{ top: 50, right: 60, bottom: 50, left: 20 }}
      colors="#0d274d"
      defs={[]}
      fill={[]}
      borderColor={{ from: "color", modifiers: [["darker", 1.4]] }}
      axisTop={null}
      axisLeft={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: 35,
      }}
      axisRight={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
        format: "$.2f",
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[]}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 15,
              fontWeight: "bold",
            },
          }
        },
      }}
    />
  );
};

export default CostPerProjectChart;
