import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import moment from "moment";
import React, { FunctionComponent } from "react";
import CardHeading from "../card-heading/CardHeading";

// @ts-ignore
export interface CurrentBillSnapshotProps {
  customerName: string;
  currentBill: number;
  start: string;
  end: string;
  links?: { text: string; to: string; external?: boolean }[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
    },
    image: {
      width: 80,
      height: 40,
    },
    titleText: {
      color: "black",
    },
    text: {
      color: "grey",
    },
    billText: {
      color: "black",
      fontWeight: 550,
    },
    billGrid: {
      padding: theme.spacing(2, 0, 0),
    },
    typeGrid: {
      margin: theme.spacing(2, 0, 0),
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    hover: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

const text = {
  title: "Current month",
  subtitle: "Month-to-date total cost",
};

export const CurrentBillSnapshot: FunctionComponent<CurrentBillSnapshotProps> = (
  props
) => {
  const classes = useStyles();
  const subtitle = `${moment.utc(props.start).format("MMMM")} ${moment
    .utc(props.start)
    .format("D")}, ${moment.utc(props.start).format("YYYY")} - ${moment
    .utc(props.end)
    .format("MMMM")} ${moment.utc(props.end).format("D")}, ${moment
    .utc(props.start)
    .format("YYYY")}`;
  return (
    <Box flex={1}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} id={"title"}>
            <CardHeading title={text.title} subtitle={subtitle} />
          </Grid>
          <Grid item xs={12} id={"bill"} className={classes.billGrid}>
            <Grid container direction={"row"} alignItems={"center"}>
              <Typography>{text.subtitle}</Typography>
              <IconButton size={"small"} style={{ paddingLeft: 5 }}>
                <HelpIcon fontSize={"small"} style={{ color: "grey" }} />
              </IconButton>
            </Grid>
            <Typography className={classes.billText}>
              {`$${Number(props.currentBill).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {props.links &&
                props.links.map((link, index) => {
                  return (
                    <Grid item key={`current-bill-snapshot-link-${index}`}>
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        href={link.to}
                        target={link.external ? "_blank" : undefined}
                      >
                        {link.text.toLocaleUpperCase()}
                      </Button>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CurrentBillSnapshot;
