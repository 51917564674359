import { Box, Grid } from "@material-ui/core";
import React from "react";
import CardHeading from "../card-heading/CardHeading";
import CostPerProjectChart, {
  CostPerProjectChartProps,
} from "../charts/CostPerProjectChart";

export interface CostPerProjectProps {
  data: CostPerProjectChartProps["data"];
  height: number;
}

const staticText = {
  title: "Cost Per Project",
  subtitle: "January 2020 - September 2020",
};

export const CostPerProject: React.FC<CostPerProjectProps> = ({
  data,
  height,
}) => {
  return (
    <Box flex={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardHeading
            title={staticText.title}
            subtitle={staticText.subtitle}
          />
        </Grid>
        <Grid item xs={12} style={{ height }}>
          <CostPerProjectChart data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};
